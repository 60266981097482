/****************************
* 		  CONFIG	 	    *
****************************/
@mixin font-face($family, $weight, $style) {
    @font-face {
        font-family: $family;
        src: url('#{$family}.eot');
        src : url("../fonts/#{$family}.ttf") format('truetype'),
              url("../fonts/#{$family}.eot") format('embedded-opentype'),
              url("../fonts/#{$family}.otf") format('opentype'),
              url("../fonts/#{$family}.woff") format('woff'),
              url("../fonts/#{$family}.woff2") format('woff2'),
              url("../fonts/#{$family}.svg") format('svg');
        font-weight: $weight;
        font-style: $style;
    }
 }
@include font-face('modelica-light','normal','normal');
@include font-face('modelica-regular','normal','normal');
@include font-face('modelica-bold','normal','normal');
@include font-face('addington-bold','normal','normal');
