/****************************
* 		 VARIABLES		 	*
****************************/
$color: (
    htmlbg: #2d2d2d,
    htmlcolor: #FFFFFF,
    menuhoverbg: #bad261,
    menuhovercolor: #2a85c3,
    mainbg: #FFFFFF,
    maincolor: #212121,
    contentcolor:#414042
);
