@import "modules/variables";
@import "modules/config";
@import "../../../node_modules/fullpage.js/dist/jquery.fullpage.min";
html{font-size:11px;font-family:'modelica-regular';
    @media only screen and (min-width: 767px) {
        font-size:13px;
    }
    @media only screen and (min-width: 992px) {
        font-size:14px;
    }
    @media only screen and (min-width: 1200px) {
        font-size:16px;
    }
}
body{background-color:map-get($color,htmlbg);font-family:'modelica-regular';font-size:16px;color:map-get($color,htmlcolor);
    header{background-color:#FFFFFF;height:5.063rem;
        .ui.grid{width:100% !important;}
        .logo{margin-top:1rem;
            a{padding:0rem 0 0 1rem;display: block;
                @media only screen and (min-width: 767px) {

                }
            }
        }
        .primarymenu{padding:0rem !important;text-align: right;
            .ui.dropdown{
                i{color:#333;font-size: 2rem;margin-top:2.5rem;}
                .ui.menu{margin-top: 1rem;}
            }
            .ui.secondary.menu{padding:0rem;margin-right:2rem;
                .item{padding:2.9rem 0rem 2.3rem 0rem;
                    font-size:0.9rem;
                    margin:0;
                    color:map-get($color,menuhovercolor);
                    width:6.5rem;
                    display: block;
                    text-align: center;
                    vertical-align: middle;
                    font-family:'modelica-regular';
                    &.solution{padding:2.4rem 0rem 2rem 0rem !important;
                        @media only screen and (min-width: 992px) {
                            width:8rem;
                        }
                    }
                    &.active,&:hover{color:map-get($color,menuhovercolor);
                        background:map-get($color,menuhoverbg);
                        border-radius:0 !important;
                        font-family:'modelica-bold';
                    }
                    @media only screen and (min-width: 992px) {
                        width:8rem;
                    }
                }
            }
        }
    }
    main{margin-top:5rem;
        #banner{background:url('../images/bg_banner.jpg') top center no-repeat;background-size: cover;
            padding:4rem 0 5rem 0;
            .fp-slidesNav.bottom{text-align: center !important;}
            #fp-nav ul li a span, .fp-slidesNav ul li a span{background: #FFFFFF !important;}
            .fp-controlArrow.fp-next{border-width: 20px 0 20px 19px;right:5%;top:55%}
            .fp-controlArrow.fp-prev{border-width: 20px 19px 20px 0;left:5%;top:55%}
        }
        #about{
            .primus{background:url('../images/bg_about.png') top center no-repeat;background-size:cover;
                .ui.grid{margin-right:0;}
                .leftimage{
                    padding:0;
                    @media only screen and (min-width: 992px) {
                        padding:0;
                    }
                    @media only screen and (min-width: 1200px) {
                        padding-bottom:1rem;
                    }
                }
                .content{
                    h1{font-family: 'addington-bold';
                        font-size:2rem;line-height:2rem;
                        color:map-get($color,mainbg);padding:0 0 1rem 1.5rem;margin:0;
                        @media only screen and (min-width: 767px) {
                            padding:2rem 0 3rem 3rem;font-size:3rem;line-height:3rem;
                        }
                        @media only screen and (min-width: 992px) {
                            padding:2rem 0 3rem 3rem;font-size:4.2rem;line-height:4rem;
                        }
                        @media only screen and (min-width: 1200px) {
                            padding:7rem 0 3rem 3rem;font-size:4.2rem;
                        }
                    }
                    p{font-family:'modelica-regular';
                        font-size:1rem;
                        line-height: 1.2rem;
                        padding:0 0 0 1.5rem;
                        @media only screen and (min-width: 767px) {
                            padding:0 5rem 0 3rem;
                            font-size:1.2rem;
                            line-height: 1.7rem;
                        }
                        @media only screen and (min-width: 992px) {
                            padding:0 5rem 0 3rem;
                            font-size:1.3rem;
                            line-height: 1.9rem;
                        }
                    }
                    button{background:none;border-radius: 0 !important;
                        border:2px solid #FFFFFF;
                        color:#FFFFFF;
                        font-family:'modelica-regular';
                        font-size:0.9rem;
                        margin:1rem 0 2rem 1.5rem;
                        @media only screen and (min-width: 767px) {
                            margin:2rem 0 2rem 3rem;
                            font-size:1.1rem;
                        }
                        @media only screen and (min-width: 992px) {
                            margin:4rem 0 2rem 3rem;
                            font-size:1.1rem;
                        }
                        @media only screen and (min-width: 1200px) {
                            margin:4rem 0 0 3rem;
                            font-size:1.1rem;
                        }
                    }
                }
            }
            .vision{background-color:#FFFFFF;position: relative;
                .ui.fluid.container{width:100% !important;}
                .ui.grid{margin-right:0;}
                .ui.right.rail{left: auto;
                    z-index: 10;
                    right:2rem;
                    width: 5rem;
                    i.huge.remove.circle.outline.icon{color:#DEDEDE;
                        font-size: 4rem;
                        cursor: pointer;
                    }
                }
                .content{padding-top:2rem;
                    h2{font-family: 'addington-bold';
                        font-size:2rem;
                        margin:1rem 0 1rem 2rem;
                        color:map-get($color,contentcolor);
                    }
                    p{font-family:'modelica-regular';
                        font-size:1rem;
                        line-height: 1.5rem;
                        color:map-get($color,contentcolor);
                        margin:1rem 0 0 2rem;
                    }
                }
                .rightimage{padding:0;
                    img{float: right;}
                }
            }
            .history{background-color:#60aa46;
                padding-bottom:3rem;
                .ui.grid{margin-right:0;}
                h2{font-family: 'addington-bold';
                    font-size:2rem;
                    color:#FFFFFF;
                    margin:3rem 0 0 2rem;
                }
                p{font-family: 'modelica-regular';
                    font-size:1.1rem;
                    margin:1rem 0 2rem 2rem;
                    color:#FFFFFF;
                    max-width:60%;
                }
            }
        }
        #solutions{background-color:#FFFFFF;
            .solutions{
                .ui.grid{margin-right:0;}
                .content{
                    h1{font-family: 'adington-bold';
                        font-size:2.7rem;
                        color:#2a85c3;
                        line-height: 3rem;
                        margin:2rem 0 1.5rem 0;
                        @media only screen and (min-width: 767px) {
                            font-size:3rem;
                            margin:3rem 0 2rem 2rem;
                            line-height:3.2rem;
                        }
                        @media only screen and (min-width: 992px) {
                            font-size:4.2rem;
                            margin:3rem 0 2rem 2rem;
                            line-height: 4rem;
                        }
                    }
                    p{font-family:'modelica-regular';
                        font-size:1.2rem;
                        color:map-get($color,contentcolor);
                        padding:0;
                        @media only screen and (min-width: 767px) {
                            font-size:1.3rem;
                            padding:0 9rem 0 2rem;
                        }
                        @media only screen and (min-width: 992px) {
                            font-size:1.3rem;
                            padding:0 9rem 0 2rem;
                        }
                    }
                    button{background:none;
                        border:2px solid #125685;
                        color:#125685;
                        font-family:'modelica-regular';
                        margin:0 0 2rem 0;
                        font-size:1rem;
                        border-radius: 0 !important;
                        @media only screen and (min-width: 767px) {
                            font-size:1.3rem;
                            margin:2rem 0 3rem 2rem;
                        }
                        @media only screen and (min-width: 992px) {
                            font-size:1.3rem;
                            margin:2rem 0 0 2rem;
                        }
                        &.active,&:hover{
                            background:#125685;
                            color:#FFFFFF;
                        }
                    }
                }
                .rightimage{margin-bottom:1rem;
                }
            }
            .solutions2{position: relative;
                .ui.right.rail{left: auto;
                    z-index: 10;
                    right:2rem;
                    width: 5rem;
                    i.huge.remove.circle.outline.icon{color:#DEDEDE;
                        font-size: 4rem;
                        cursor: pointer;
                    }
                }
                p{font-family:'modelica-regular';
                    font-size:1.3rem;
                    color:map-get($color,contentcolor);
                }
                .ui.list{color:map-get($color,contentcolor);
                    font-size:1.2rem !important;
                    margin:2rem 0;
                    font-family:'modelica-regular';
                    i.icon.square:before{background:none !important;color:#125685 !important;}
                    .header{color:map-get($color,contentcolor);
                        font-size: 1.2rem;
                        font-family:'modelica-bold';
                    }
                }
                .maps{margin:0rem !important;position: relative;
                    @media only screen and (min-width: 992px) {
                        margin-top:2rem;
                    }
                    .ui.fluid.container{margin: 0;
                        @media only screen and (min-width: 992px) {
                            margin: auto;
                        }
                    }
                    .ui.grid{margin-right:0;display: inherit !important;
                        @media only screen and (min-width: 992px) {
                            display: flex;
                        }
                    }
                    img{position:relative;padding:0;}
                    .dep.popup{background:#f7c12c;padding:1rem !important;border:none;margin-left:-1.1rem !important;color:#FFFFFF;
                        .header{font-family: 'modelica-bold';}
                        .content{font-family:'modelica-regular';}
                        &:before{background:#f7c12c !important;}
                    }
                    .porterp.popup{background:#3d4a99;padding:1rem !important;border:none;margin-left:-1.1rem !important;color:#FFFFFF;
                        .header{font-family: 'modelica-bold';}
                        .content{font-family:'modelica-regular';}
                        &:before{background:#3d4a99 !important;border:none;}
                    }
                    .cto.popup{background:#f4ea38;padding:1rem !important;border:none;margin-left:-1.1rem !important;color:#FFFFFF;
                        .header{font-family: 'modelica-bold';}
                        .content{font-family:'modelica-regular';}
                        &:before{background:#f4ea38 !important;border:none;}
                    }
                    .inaportne.popup{background:#FFFFFF;padding:1rem !important;border:none;margin-left:-1.1rem !important;color:#000000;
                        .header{font-family: 'modelica-bold';}
                        .content{font-family:'modelica-regular';}
                        &:before{background:#FFFFFF !important;border:none;}
                    }
                    .lspor.popup{background:#7fccdf;padding:1rem !important;border:none;margin-left:-1.1rem !important;color:#FFFFFF;
                        .header{font-family: 'modelica-bold';}
                        .content{font-family:'modelica-regular';}
                        &:before{background:#7fccdf !important;border:none;}
                    }
                    .depo.sulawesi{position: absolute;top:18.6rem;right:27.1rem;padding:0;margin:0;width: 1rem;}
                    .depo.kalimantan{position: absolute;top:26.8rem;right:46.4rem;padding:0;margin:0;width: 1rem;}
                    .depo.sumatra{position: absolute;top:19.6rem;left:10.3rem;padding:0;margin:0;width:1rem;}
                    .portserp.sulawesi{position: absolute;top:19.6rem;right:28.4rem;padding:0;margin:0;width:1rem}
                    .portserp.sumatra1{position: absolute;top:19.6rem;left:9.4rem;padding:0;margin:0;width:1rem;}
                    .portserp.sumatra2{position: absolute;top:17.4rem;left:7.1rem;padding:0;margin:0;width:1rem;}
                    .ctos.sulawesi{position: absolute;top:29rem;right:36.5rem;padding:0;margin:0;width:1rem;}
                    .ctos.jawa{position: absolute;bottom:16.8rem;left:31.9rem;padding:0;margin:0;width:1rem;}
                    .ctos.malay{position: absolute;top:13.7rem;left:13.9rem;padding:0;margin:0;width:1rem;}
                    .inaportnet.sumatra{position: absolute;top:15.2rem;left:6.3rem;padding:0;margin:0;width:1rem;}
                    .inaportnet.sulawesi{position: absolute;top:30.3rem;right:38rem;padding:0;margin:0;width:1rem;}
                    .lsport.kalimantan1{position: absolute;top:22.7rem;left:35.1rem;padding:0;margin:0;width:1rem;}
                    .lsport.kalimantan2{position: absolute;top:23rem;left:38.4rem;padding:0;margin:0;width:1rem;}
                    .lsport.sulawesi{position: absolute;top:30.4rem;right:36.8rem;padding:0;margin:0;width:1rem;}
                    .lsport.maluku{position: absolute;top:26.4rem;right:23.2rem;padding:0;margin:0;width:1rem;}
                }
            }
            .solutions3{background-color:#125685;
                .ui.right.rail{left: auto;
                    z-index: 10;
                    right:2rem;
                    width: 5rem;
                    i.huge.remove.circle.outline.icon{color:#DEDEDE;
                        font-size: 4rem;
                        cursor: pointer;
                    }
                }
                p{font-family:'modelica-regular';
                    font-size:1.3rem;
                    color:#FFFFFF;
                    margin-top:4rem;
                }
                .ui.list{margin:2rem 0 5rem 0;
                    .item{color:#FFFFFF;
                        i.icon.square:before{background:none !important;color:#FFFFFF !important;}
                        font-size:1.3rem;
                        font-family:'modelica-regular';
                    }
                }
            }
        }
        #clients{position: relative;background:#FFFFFF;
            .ui.grid{margin-right:0;}
            .clients1{
                position: absolute;
                top:0;
                left: 0;
                width: 9.75rem;
                height: auto;
                @media only screen and (min-width: 992px) {
                    height:31.5rem;
                    width:16.75rem;
                }
                @media only screen and (min-width: 1200px) {
                    width: 14.75rem;
                    height: 26.5rem;
                }
            }
            .clients2{
                position: absolute;
                top:0;
                right: 0;
                width: 9.75rem;
                height:auto;
                @media only screen and (min-width: 992px) {
                    height:31.5rem;
                    width:16.75rem;
                }
                @media only screen and (min-width: 1200px) {
                    width: 14.75rem;
                    height: 26.5rem;
                }
            }
            h1{font-family: 'addington-bold';
                font-size:3rem;
                margin-top:1rem;
                @media only screen and (min-width: 767px) {
                    font-size:3rem;
                    margin-top:2rem;
                }
                @media only screen and (min-width: 992px) {
                    font-size:4.2rem;
                    margin-top:2rem;
                }
            }
            p{font-family:'modelica-regular';
                font-size:1.2rem;
                text-align: justify;
                color:map-get($color,contentcolor);
                margin-bottom:2rem;
                line-height: 1.8rem;
                @media only screen and (min-width: 767px) {
                    margin-left:1.4rem;
                    font-size:1.2rem;
                }
                @media only screen and (min-width: 992px) {
                    margin-left:1.5rem;
                    font-size:1.3rem;
                }
                @media only screen and (min-width: 1200px) {
                    margin-left:0;
                }
            }
            img{margin-bottom:2rem;}
        }
        .parallax-window {
        	min-height:20rem;
            min-width: 100%;
        	background: transparent;
            @media only screen and (min-width: 992px) {
                min-height:40rem;
            }
        }
        .parallax-window2 {
        	min-height:25.375rem;
            min-width: 100%;
        	background: transparent;
            @media only screen and (min-width: 992px) {
                min-height:40rem;
            }
        }
        #principals{background-color:#FFFFFF;z-index: 10;position: relative;
            margin:0 !important;width: 100% !important;
            @media only screen and (min-width: 992px) {
                margin:auto;
            }
            .ui.fluid.container{margin:0 !important;padding:0;width: 100% !important;}
            .ui.grid{margin-right:0;}
            .leftimage{margin:0;padding:0;
                @media only screen and (min-width: 992px) {
                    margin-top:4rem;
                }
            }
            .content{
                h1{font-family: 'addington-bold';
                    font-size:3rem;
                    margin:1.5rem 0;
                    @media only screen and (min-width: 992px) {
                        font-size:4.2rem;
                        margin:2rem 0;
                    }
                }
                p{font-family:'modelica-regular';
                    font-size:1.2rem;
                    color:map-get($color,contentcolor);
                    margin:0 1rem 1rem 0;
                    @media only screen and (min-width: 992px) {
                        font-size:1.3rem;
                        margin:0 3rem 2rem 0;
                    }
                }
                img{margin-bottom:3rem;}
            }
        }
        #contacts{background-color:#e7f5f9;margin: 0 !important;
            h1{font-family: 'addington-bold';
                font-size:3rem;
                margin:2rem 0;
                @media only screen and (min-width: 992px) {
                    font-size:4.2rem;
                    margin:3rem 0 3rem 0;
                }
            }
            .ui.grid{margin-right:0;}
            .content{
                img.ui.image{margin-bottom:2rem;}
                .ui.list{margin:2rem 0 3rem 0;
                    .item{color:map-get($color,contentcolor);
                        font-size:0.9rem;
                        font-family:'modelica-regular';
                        line-height: 1.5rem;
                        .header{color:map-get($color,contentcolor);
                            font-weight: bold;
                            font-size: 1.3rem;
                            font-family:'modelica-bold';
                            margin-top:1rem;
                            margin-bottom:0.1rem;
                        }
                        .ui.celled.horizontal.list{padding:0;margin:0;
                            .item{border:none;vertical-align: top;
                                font-size:0.9rem;
                                font-family:'modelica-regular';
                                line-height: 1.5rem;
                                padding:0 !important;
                                &.kota{min-width: 6rem;padding-left:0;}
                            }
                        }
                        ol.ui.list{padding:0;margin:0;
                            li{margin-left:1.4rem;
                                font-size:0.9rem;
                                font-family:'modelica-regular';
                                line-height: 1.5rem;
                            }
                        }
                    }
                }
            }
            .ui.form{padding-bottom: 1rem;
                label{font-family: 'modelica-bold';
                    font-weight:bold;
                    color:map-get($color,contentcolor);
                }
                input,textarea{background-color:#e5e5e5 !important;border-radius: 0 !important;}
                button{background:#125685;border-radius: 0 !important;
                color:#FFFFFF;}
            }
        }
    }
    footer{margin:3rem 0 1rem 0;
        .ui.horizontal.list{
            @media only screen and (min-width: 767px) {
                text-align: left;
            }
        }
        p{text-align: center;
            font-family: 'modelica-regular';
            font-size: 1rem;
            line-height: 1.5rem;
            @media only screen and (min-width: 992px) {
                text-align: right;
            }
        }
    }
}
